/****
 * FOR INTRA ACCESS
 */

export const API_URL = 'http://192.168.10.88:5001/api';
export const PUBLIC_NAS_FOLDER = "http://192.168.10.88:9090/Inteliqo";
export const ELLIDER_API_URL = 'http://192.168.10.88:6001/api'
export const QR_URL = 'http://travancoremedicity.in:8536'

/**
 * FOR CLOUD ACCESS
 */

// export const API_URL = 'http://travancoremedicity.in:5001/api';
// export const PUBLIC_NAS_FOLDER = "http://travancoremedicity.in:8537/NAS/";
// export const ELLIDER_API_URL = 'http://travancoremedicity.in:6001/api'
// export const QR_URL = 'http://travancoremedicity.in:8536'